import styled from 'styled-components';
import { Row } from 'components/Row';
import { Form } from 'formik';

export const Wrapper = styled.div`
  background: black;
  padding-top: 180px;
  min-height: 100vh;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    padding-top: 150px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.p`
  font-family: Brother;
  font-size: 80px;
  font-weight: 500;
  line-height: 35px;
  color: var(--color-white);
  height: 60px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  margin: 0 82px;
  margin-bottom: 18px;
`;

export const FormContainer = styled(Form)`
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    width: 350px;
    padding: 30px;
    margin: 20px;
    height: auto;
    margin-top: -30px;
  }
`;

export const TitleText = styled.p`
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 23px;
  color: white;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
`;

export const EyeContainer = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const SecondaryText = styled.p`
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 23px;
  color: white;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

export const SecondaryTextWrap = styled.div`
  width: 100%;

  p {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ResetPasswordContainer = styled(Row)`
  border-radius: 40px;
  text-align: center;
  width: 100%;
  position: relative;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    width: 300px;
  }
`;
